const COMMON_POLLING_INTERVAL = 12000
const DAY_CHART_POLLING_INTERVAL = 60 * 4 * 1000
const SITE_POLLING_INTERVAL = COMMON_POLLING_INTERVAL
const THING_POLLING_INTERVAL = COMMON_POLLING_INTERVAL
const MODE_VIEW = 'view'
const MODE_EDIT = 'edit'
const SITE_SCHEMA_EDITOR_ICON_SIZE = { width: 24, height: 32 }
const SITE_SCHEMA_SCROLLBAR_OFFSET = 25
const SITE_SCHEMA_FONT_SIZE = 20
const NUMBER_DEVICES_FOR_FILTER = 4
const TIME_FORMAT = 'DD.MM.YYYY HH:mm'
const DATE_FORMAT = 'DD.MM.YYYY'
const EXPORT_MAX_DAYS = 32
const EXPORT_MAX_THINGS = 10

const KEYS = {
    
        POWER_CHARGING_STATIONS: 'POWER_CHARGING_STATIONS',
    
        POWER_HEATING: 'POWER_HEATING',
    
        INPUT_ENERGY: 'INPUT_ENERGY',
    
        OUTPUT_ENERGY: 'OUTPUT_ENERGY',
    
        MIN_SOC_PEAK_SHAVING_OPT: 'MIN_SOC_PEAK_SHAVING_OPT',
    
        MAX_GRID_POWER_PEAK_SHAVING: 'MAX_GRID_POWER_PEAK_SHAVING',
    
        MIN_SOC_BLACKOUT: 'MIN_SOC_BLACKOUT',
    
        MIN_SOC_BACKUP_ENERGY: 'MIN_SOC_BACKUP_ENERGY',
    
        MIN_SOC_SELF_CONSUMPTION_OPT: 'MIN_SOC_SELF_CONSUMPTION_OPT',
    
        LOAD_SHEDDING_LEVELS: 'LOAD_SHEDDING_LEVELS',
    
        CHARGING_STRATEGY: 'CHARGING_STRATEGY',
    
        EMS_MIN_POWER_CHARGE: 'EMS_MIN_POWER_CHARGE',
    
        POWER_THRESHOLD_RECOMMENDED_OP: 'POWER_THRESHOLD_RECOMMENDED_OP',
    
        POWER_THRESHOLD_NORMAL_OP: 'POWER_THRESHOLD_NORMAL_OP',
    
        CONTROLLER_MODE: 'CONTROLLER_MODE',
    
        PAUSE_CHARGING: 'PAUSE_CHARGING',
    
        TARGET_LOCAL_POWER_OFFSET: 'TARGET_LOCAL_POWER_OFFSET',
    
        OVERALL_GRID_CONNECTED: 'OVERALL_GRID_CONNECTED',
    
        OVERALL_SYSTEM_AVAILABLE: 'OVERALL_SYSTEM_AVAILABLE',
    
        OVERALL_SYSTEM_RUNNING: 'OVERALL_SYSTEM_RUNNING',
    
        OVERALL_SYSTEM_FAILURE: 'OVERALL_SYSTEM_FAILURE',
    
        OVERALL_ACTIVE_POWER: 'OVERALL_ACTIVE_POWER',
    
        OVERALL_LOCAL_POWER: 'OVERALL_LOCAL_POWER',
    
        CHARGING_TIME: 'CHARGING_TIME',
    
        OPERATING_HOURS: 'OPERATING_HOURS',
    
        OVERALL_MAX_POWER_DISCHARGE: 'OVERALL_MAX_POWER_DISCHARGE',
    
        OVERALL_MAX_POWER_CHARGE: 'OVERALL_MAX_POWER_CHARGE',
    
        OVERALL_MAX_SOC: 'OVERALL_MAX_SOC',
    
        OVERALL_STATE_OF_CHARGE: 'OVERALL_STATE_OF_CHARGE',
    
        OVERALL_REMAINING_ENERGY_DISCHARGE: 'OVERALL_REMAINING_ENERGY_DISCHARGE',
    
        OVERALL_REMAINING_ENERGY_CHARGE: 'OVERALL_REMAINING_ENERGY_CHARGE',
    
        CURRENT: 'CURRENT',
    
        VALUE: 'VALUE',
    
        VOLTAGE: 'VOLTAGE',
    
        CONNECTION: 'CONNECTION',
    
        CONNECTIONS: 'CONNECTIONS',
    
        STATE_CODE: 'STATE_CODE',
    
        ERROR_CODES: 'ERROR_CODES',
    
        ACKNOWLEDGE_ERRORS: 'ACKNOWLEDGE_ERRORS',
    
        ON_OFF: 'ON_OFF',
    
        OPERATING_MODE: 'OPERATING_MODE',
    
        OPERATING_MODES: 'OPERATING_MODES',
    
        OPERATING_MODE_EMS: 'OPERATING_MODE_EMS',
    
        TARGET_POWER: 'TARGET_POWER',
    
        POWER: 'POWER',
    
        ACTIVE_POWER: 'ACTIVE_POWER',
    
        REACTIVE_POWER: 'REACTIVE_POWER',
    
        APPARENT_POWER: 'APPARENT_POWER',
    
        POWER_FACTOR: 'POWER_FACTOR',
    
        FREQUENCY: 'FREQUENCY',
    
        VOLTAGE_P1: 'VOLTAGE_P1',
    
        VOLTAGE_P2: 'VOLTAGE_P2',
    
        VOLTAGE_P3: 'VOLTAGE_P3',
    
        CURRENT_P1: 'CURRENT_P1',
    
        CURRENT_P2: 'CURRENT_P2',
    
        CURRENT_P3: 'CURRENT_P3',
    
        POWER_P1: 'POWER_P1',
    
        POWER_P2: 'POWER_P2',
    
        POWER_P3: 'POWER_P3',
    
        STATE_OF_CHARGE: 'STATE_OF_CHARGE',
    
        FLOW_TEMPERATURE: 'FLOW_TEMPERATURE',
    
        RETURN_TEMPERATURE: 'RETURN_TEMPERATURE',
    
        VOLUME_FLOW_RATE: 'VOLUME_FLOW_RATE',
    
        FLOWED_VOLUME: 'FLOWED_VOLUME',
    
        CHARGED_ENERGY: 'CHARGED_ENERGY',
    
        DISCHARGED_ENERGY: 'DISCHARGED_ENERGY',
    
        CAPACITY: 'CAPACITY',
    
        CHARGING_CYCLES: 'CHARGING_CYCLES',
    
        HEALTH_STATE: 'HEALTH_STATE',
    
        CELL_TEMPERATURE: 'CELL_TEMPERATURE',
    
        RACK_TEMPERATURES: 'RACK_TEMPERATURES',
    
        RACK_HUMIDITIES: 'RACK_HUMIDITIES',
    
        MAX_SOC: 'MAX_SOC',
    
        MIN_SOC: 'MIN_SOC',
    
        INSTALLED_CAPACITY: 'INSTALLED_CAPACITY',
    
        INSTALLED_POWER: 'INSTALLED_POWER',
    
        MAX_POWER_CHARGE: 'MAX_POWER_CHARGE',
    
        MAX_POWER_GENERATION_AC: 'MAX_POWER_GENERATION_AC',
    
        MAX_POWER_DISCHARGE: 'MAX_POWER_DISCHARGE',
    
        GRID_FEED_IN_LIMIT: 'GRID_FEED_IN_LIMIT',
    
        TEMPERATURES: 'TEMPERATURES',
    
        HOT_WATER_OUTLET_TEMPERATURE: 'HOT_WATER_OUTLET_TEMPERATURE',
    
        HEATING_OUTLET_TEMPERATURES: 'HEATING_OUTLET_TEMPERATURES',
    
        CP_STATE_CODE: 'CP_STATE_CODE',
    
        EV_STATE_CODE: 'EV_STATE_CODE',
    
        START_STOP_CHARGING: 'START_STOP_CHARGING',
    
        STATION_AVAILABILITY: 'STATION_AVAILABILITY',
    
        CONSUMED_ENERGY_TOTAL: 'CONSUMED_ENERGY_TOTAL',
    
        CONSUMED_ENERGY_ACTUAL: 'CONSUMED_ENERGY_ACTUAL',
    
        LAST_RFID_CARD: 'LAST_RFID_CARD',
    
        MAX_CURRENT_CHARGE: 'MAX_CURRENT_CHARGE',
    
        MAX_CURRENT_DISCHARGE: 'MAX_CURRENT_DISCHARGE',
    
        HARMONICS: 'HARMONICS',
    
        TOTAL_HARMONIC_DISTORTION: 'TOTAL_HARMONIC_DISTORTION',
    
        DISTORTION_FACTOR: 'DISTORTION_FACTOR',
    
        VOLTAGE_P1_P2: 'VOLTAGE_P1_P2',
    
        VOLTAGE_P2_P3: 'VOLTAGE_P2_P3',
    
        VOLTAGE_P3_P1: 'VOLTAGE_P3_P1',
    
        ACTIVE_VOLTAGE: 'ACTIVE_VOLTAGE',
    
        REACTIVE_VOLTAGE: 'REACTIVE_VOLTAGE',
    
        TOTAL_VOLTAGE: 'TOTAL_VOLTAGE',
    
        ACTIVE_CURRENT: 'ACTIVE_CURRENT',
    
        REACTIVE_CURRENT: 'REACTIVE_CURRENT',
    
        TOTAL_CURRENT: 'TOTAL_CURRENT',
    
        CONSUMED_ENERGY: 'CONSUMED_ENERGY',
    
        DELIVERED_ENERGY: 'DELIVERED_ENERGY',
    
        IMPORTED_ENERGY: 'IMPORTED_ENERGY',
    
        EXPORTED_ENERGY: 'EXPORTED_ENERGY',
    
        HEATING_ENERGY: 'HEATING_ENERGY',
    
        COOLING_ENERGY: 'COOLING_ENERGY',
    
        FUEL_LEVEL: 'FUEL_LEVEL',
    
        FUEL_CONSUMPTION: 'FUEL_CONSUMPTION',
    
        FUEL_CONSUMPTION_ACTUAL: 'FUEL_CONSUMPTION_ACTUAL',
    
        COOLANT_TEMPERATURE: 'COOLANT_TEMPERATURE',
    
        OIL_TEMPERATURE: 'OIL_TEMPERATURE',
    
        OIL_PRESSURE: 'OIL_PRESSURE',
    
        COP: 'COP',
    
        ANNUAL_COP: 'ANNUAL_COP',
    
        ELECTRICAL_ENERGY: 'ELECTRICAL_ENERGY',
    
        ENVIRONMENTAL_ENERGY: 'ENVIRONMENTAL_ENERGY',
    
        THERMAL_ENERGY: 'THERMAL_ENERGY',
    
        ELECTRICAL_POWER: 'ELECTRICAL_POWER',
    
        ENVIRONMENTAL_POWER: 'ENVIRONMENTAL_POWER',
    
        THERMAL_POWER: 'THERMAL_POWER',
    
        REFRIGERANT_FLOW_TEMPERATURE: 'REFRIGERANT_FLOW_TEMPERATURE',
    
        REFRIGERANT_RETURN_TEMPERATURE: 'REFRIGERANT_RETURN_TEMPERATURE',
    
        TARGET_ROOM_TEMPERATURE: 'TARGET_ROOM_TEMPERATURE',
    
        HC_OUTDOOR_TEMPERATURE: 'HC_OUTDOOR_TEMPERATURE',
    
        HC_PARALLEL_SHIFT: 'HC_PARALLEL_SHIFT',
    
        HC_SLOPE: 'HC_SLOPE',
    
        LOCK_UNTIL: 'LOCK_UNTIL',
    
        INPUTS_POWER: 'INPUTS_POWER',
    
        INPUT_POWER: 'INPUT_POWER',
    
        PRODUCED_ENERGY: 'PRODUCED_ENERGY',
    
        VOLTAGES: 'VOLTAGES',
    
        CURRENTS: 'CURRENTS',
    
        VALVE_POSITION: 'VALVE_POSITION',
    
        TARGET_TEMPERATURE: 'TARGET_TEMPERATURE',
    
        SELF_SUFFICIENCY: 'SELF_SUFFICIENCY',
    
        ENERGY_PRODUCED: 'ENERGY_PRODUCED',
    
        ENERGY_CONSUMED: 'ENERGY_CONSUMED',
    
        ENERGY_CONSUMED_CALC: 'ENERGY_CONSUMED_CALC',
    
        ENERGY_IMPORTED: 'ENERGY_IMPORTED',
    
        ENERGY_EXPORTED: 'ENERGY_EXPORTED',
    
        ENERGY_CHARGED: 'ENERGY_CHARGED',
    
        ENERGY_DISCHARGED: 'ENERGY_DISCHARGED',
    
        POWER_PRODUCTION: 'POWER_PRODUCTION',
    
        POWER_CONSUMPTION: 'POWER_CONSUMPTION',
    
        POWER_CONSUMPTION_CALC: 'POWER_CONSUMPTION_CALC',
    
        POWER_GRID: 'POWER_GRID',
    
        POWER_GRID_REMAINING: 'POWER_GRID_REMAINING',
    
        POWER_STORAGE: 'POWER_STORAGE',
    
        ENERGY_APPLIANCES: 'ENERGY_APPLIANCES',
    
        ENERGY_CHARGING_STATIONS: 'ENERGY_CHARGING_STATIONS',
    
        ENERGY_HEATING: 'ENERGY_HEATING',
    
        POWER_APPLIANCES: 'POWER_APPLIANCES',
    
}

// TODO: ELECTRICITY_METER & ENERGY_METER & SMART_METER are DEPRECATED, remove when fully merged
const CHARGING_POINT_TYPES = [
    'CHARGING_POINT_DC',
    'CHARGING_POINT_AC'
]
const METER_TYPES = [
    'SMART_METER',
    'ELECTRICITY_METER',
    'ENERGY_METER',
    'ELECTRICITY_METER_AC',
    'ELECTRIC_CIRCUIT'
]

const THING_CONTROLS_SUPPORTED_KEYS = [
    'ON_OFF',
    'STATION_AVAILABILITY',
    'START_STOP_CHARGING',
    'OPERATING_MODE',
    'OPERATING_MODE_EMS',
    'OPERATING_MODES',
    'HC_PARALLEL_SHIFT',
    'HC_SLOPE',
    'TARGET_TEMPERATURE',
    'TARGET_ROOM_TEMPERATURE',
    'VALVE_POSITION',
    'MAX_POWER_CHARGE',
    'MAX_POWER_DISCHARGE',
    'MAX_CURRENT_CHARGE',
    'MIN_SOC',
    'MAX_SOC',
    'TARGET_POWER',
    'MIN_SOC_PEAK_SHAVING_OPT',
    'MIN_SOC_SELF_CONSUMPTION_OPT',
    'MAX_GRID_POWER_PEAK_SHAVING',
    'MIN_SOC_BLACKOUT',
    'LOAD_SHEDDING_LEVELS',
    'OPERATING_MODE_EMS',
    'EMS_MIN_POWER_CHARGE',
    'EV_STATE_CODE',
    'POWER_THRESHOLD_RECOMMENDED_OP',
    'POWER_THRESHOLD_NORMAL_OP'
]

const THING_CONTROLS_SUPPORTED_SETTINGS = [
    'OPERATING_MODE_EMS',
    'POWER_THRESHOLD_RECOMMENDED_OP',
    'POWER_THRESHOLD_NORMAL_OP'
]

const THING_TYPE = {
    
        PHOTOVOLTAIC: 'PV',
    
        ENERGY_METER: 'ENERGY_METER',
    
        HOT_WATER_CIRCUIT: 'HOT_WATER_CIRCUIT',
    
        THERMAL_METER: 'THERMAL_METER',
    
        INVERTER: 'INVERTER',
    
        HEATING_CIRCUIT: 'HEATING_CIRCUIT',
    
        FUEL_GENERATOR: 'FUEL_GENERATOR',
    
        CLIENT_THING: 'CLIENT_THING',
    
        VIRTUAL_THING: 'VIRTUAL_THING',
    
        EDGE_DEVICE: 'EDGE_DEVICE',
    
        CONSUMER: 'CONSUMER',
    
        GROUP: 'GROUP',
    
        BUFFER_TANK: 'BUFFER_TANK',
    
        SIMPLE_THING: 'SIMPLE_THING',
    
        ELECTRIC_CIRCUIT: 'ELECTRIC_CIRCUIT',
    
        ELECTRICITY_METER_AC: 'ELECTRICITY_METER_AC',
    
        ELECTRICITY_METER: 'ELECTRICITY_METER',
    
        BATTERY: 'BATTERY',
    
        CHARGING_POINT_AC: 'CHARGING_POINT_AC',
    
        HEAT_PUMP: 'HEAT_PUMP',
    
        CHARGING_POINT_DC: 'CHARGING_POINT_DC',
    
        SMART_METER: 'SMART_METER',
    
}

const AVAILABLE_THING_TYPES = Object.values(THING_TYPE)

const DEPRECATED_THING_TYPES = [
    'SMART_METER',
    'ELECTRICITY_METER',
    'ENERGY_METER'
]

const METER_READING_PROVIDER_TYPES = [
    'PV',
    'BATTERY',
    'CHARGING_POINT_DC',
    'CHARGING_POINT_AC',
    'ELECTRIC_CIRCUIT',
    'ELECTRICITY_METER_AC',
    'ELECTRICITY_METER',
    'ENERGY_METER',
    'SMART_METER',
    'HEAT_PUMP',
    'THERMAL_METER',
    'FUEL_GENERATOR',
    'HOT_WATER_CIRCUIT',
    'HEATING_CIRCUIT',
    'CONSUMER'
]

const EMS_CONTROLLED_THING_TYPES = [
    'CONSUMER','CHARGING_POINT_AC','CHARGING_POINT_DC','BATTERY'
]

const EMS_CONTROLLED_KEYS = [
    'MAX_CURRENT_CHARGE','MAX_POWER_CHARGE','TARGET_POWER'
]

const METER_READING_KEYS = [
  'CHARGED_ENERGY','DISCHARGED_ENERGY','PRODUCED_ENERGY','CONSUMED_ENERGY','INPUT_ENERGY','OUTPUT_ENERGY','IMPORTED_ENERGY','EXPORTED_ENERGY','DELIVERED_ENERGY','CONSUMED_ENERGY_TOTAL','ELECTRICAL_ENERGY','FLOWED_VOLUME','HEATING_ENERGY','COOLING_ENERGY','ENVIRONMENTAL_ENERGY','THERMAL_ENERGY','VALUE','FUEL_CONSUMPTION'
]

const HIDDEN_DATA_POINT_KEYS = [
    
    'OVERALL_STATE_OF_CHARGE',
    
    'OVERALL_GRID_CONNECTED',
    
    'OVERALL_SYSTEM_AVAILABLE',
    
    'OVERALL_SYSTEM_RUNNING',
    
    'OVERALL_SYSTEM_FAILURE',
    
    'OVERALL_ACTIVE_POWER',
    
    'OVERALL_LOCAL_POWER',
    
    'OVERALL_MAX_POWER_CHARGE',
    
    'OVERALL_MAX_POWER_DISCHARGE',
    
    'OVERALL_MAX_SOC',
    
    'OVERALL_REMAINING_ENERGY_CHARGE',
    
    'OVERALL_REMAINING_ENERGY_DISCHARGE',
    
]

const COLOR_ENERGY_PRODUCED = '#FED766'
const COLOR_POWER_GRID = '#848C8E'
const COLOR_POWER_STORAGES = '#00A676'
const COLOR_ENERGY_CONSUMED = '#007DA0'
const COLOR_STATE_OF_CHARGE = '#D64C48'

const COLOR_PRIMARY = '#007DA0'
const COLOR_GREY_LIGHTER = '#f8f9fa'
const COLOR_GREY_MEDIUM = '#dae0e5'
const COLOR_GREY_DARKER = '#bcbcbc'
const COLOR_GREY_BLUE_DARK = '#343a40'

export const GENERIC_MANUFACTURER_ID = 'generic-manufacturer-id'

export const OCPP_JSON = 'OCPP_JSON'
export const MODBUS_TCP = 'MODBUS_TCP'
export const MODBUS_RTU = 'MODBUS_RTU'
export const HTTP = 'HTTP'

export const UI_TRUNCATION_CUT_OFF_0 = '5'
export const UI_TRUNCATION_CUT_OFF_1000 = '0.005'

export default {
    COMMON_POLLING_INTERVAL,
    SITE_POLLING_INTERVAL,
    THING_POLLING_INTERVAL,
    DAY_CHART_POLLING_INTERVAL,
    MODE_VIEW,
    MODE_EDIT,
    SITE_SCHEMA_EDITOR_ICON_SIZE,
    SITE_SCHEMA_SCROLLBAR_OFFSET,
    SITE_SCHEMA_FONT_SIZE,
    NUMBER_DEVICES_FOR_FILTER,
    TIME_FORMAT,
    DATE_FORMAT,
    CHARGING_POINT_TYPES,
    METER_TYPES,
    AVAILABLE_THING_TYPES,
    DEPRECATED_THING_TYPES,
    METER_READING_PROVIDER_TYPES,
    METER_READING_KEYS,
    HIDDEN_DATA_POINT_KEYS,
    THING_CONTROLS_SUPPORTED_KEYS,
    THING_CONTROLS_SUPPORTED_SETTINGS,
    OCPP_JSON,
    MODBUS_TCP,
    MODBUS_RTU,
    HTTP,
    COLOR_ENERGY_PRODUCED,
    COLOR_POWER_GRID,
    COLOR_POWER_STORAGES,
    COLOR_ENERGY_CONSUMED,
    COLOR_STATE_OF_CHARGE,
    COLOR_PRIMARY,
    COLOR_GREY_LIGHTER,
    COLOR_GREY_MEDIUM,
    COLOR_GREY_DARKER,
    COLOR_GREY_BLUE_DARK,
    EXPORT_MAX_DAYS,
    EXPORT_MAX_THINGS,
    THING_TYPE,
    EMS_CONTROLLED_KEYS,
    EMS_CONTROLLED_THING_TYPES,
    KEYS,
    UI_TRUNCATION_CUT_OFF_0,
    UI_TRUNCATION_CUT_OFF_1000,
    GENERIC_MANUFACTURER_ID
}
